import React from 'react'
import { NavLink } from "react-router-dom";
import Navigation from '../navigation/Navigation';
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import Logo from '../../assets/NyLogga-transparent.png';
import './footer.css';

// All text on this page
let text = require('./text.json');

type Props = {
  language: string;
}

const Footer = (props: Props) => {
  return (
    <div className='footer'>
      <div className='footer__upper'>
        <div className='footer__upper-left'>
          <img src={Logo} alt='logo' />
          <div className='footer__navigation'>
            <Navigation language={props.language} />
          </div>
        </div>

        <div className='footer__upper-center'>
          <p className='p2 text-center no_bottom'>{ text[props.language]["phone"] }:</p>
          <div className='contact__employees-contents_employee-line'>
            <AiOutlinePhone size={20} />
            <p className='p-bold3'> 010-456 26 00 </p>
          </div>

          <p className='p2 text-center no_bottom'>{ text[props.language]["orders"] }:</p>
          <div className='contact__employees-contents_employee-line'>
            <AiOutlineMail size={20} />
            <p className='p-bold3'> order@infragross.se </p>
          </div>
        </div>

        <div className='footer__uc'>
          {(props.language === 'sv') 
            ? (
              <a href="https://www.uc.se/risksigill2/?showorg=556815-6870&amp;language=swe&amp;special=" title="Sigillet är utfärdat av UC AB. Klicka på bilden för information om UC:s Riskklasser." target="_blank">
                <img src="https://www.uc.se/ucsigill2/sigill?org=556815-6870&amp;language=swe&amp;product=psa&amp;special=&amp;fontcolor=b&amp;type=png" alt="" style={{border: "0"}} />          
              </a>
            )
            : (
              <a href="https://www.uc.se/risksigill2/?showorg=556815-6870&amp;language=eng&amp;special=" title="Sigillet är utfärdat av UC AB. Klicka på bilden för information om UC:s Riskklasser." target="_blank">
                <img src="https://www.uc.se/ucsigill2/sigill?org=556815-6870&amp;language=eng&amp;product=psa&amp;special=&amp;fontcolor=b&amp;type=png" alt="" style={{border: "0"}} />          
              </a>
          ) }
        </div>

      </div>

      <hr />
      
      <div className='footer__lower'>
        <div className='footer__component-left footer__bottom-links'>
          <NavLink to='/privacy' className='b-underline'>{text[props.language]["privacy"]}</NavLink>
          <NavLink to='/cookies' className='b-underline'>{text[props.language]["cookie"]}</NavLink>
        </div>
        <div className='footer__copyright'>
          <p className='p3'>© 2024 Infragross i Sverige AB</p>
        </div>
      </div>

      

    </div>
  )
}

export default Footer;