import React from 'react'
import './blocks.css';

type Props = {
  imageSide: string; // Which side the image should be on
  slogan: string; // A small slogan at the top
  title: string;
  text: string;
  buttons: React.ReactNode; // A section containing buttons
  image: string; // A link to the image
  filled: boolean; // Whether to fill it with the infra blue color
}

/**
 * @brief Building block contains two horizontal parts, one with an image and one with text and buttons
 * @param props 
 * @returns HTML
 */
const TextAndImage = (props: Props) => {
  const directionClass: string = (props.imageSide === 'left') ? "blocks__text-and-image_imageside-left" : "";

  const filledBackground = (props.filled) ? {'background': 'var(--infra-blue)'} : {};
  const filledColor = (props.filled) ? {'color': 'var(--infra-white)'} : {};

  return (
    <div className={'blocks__text-and-image ' + directionClass} style={filledBackground}>
      <div className='blocks__text-and-image_text'>
        <p className='p2 no-mobile' style={filledColor} > { props.slogan } </p>
        <h2 className='h2' style={filledColor}> { props.title } </h2>
        <p className='p2' style={filledColor}> { props.text } </p>
        <div className='page__button-container-horizontal'>
          { props.buttons }
        </div>
      </div>
      <div className='blocks__text-and-image_image'>
        <img src={props.image} alt={props.slogan + '_image'} />
      </div>
      <p className='p2 only-mobile text-center' style={filledColor} > { props.slogan } </p>
    </div>
  )
}

export default TextAndImage;