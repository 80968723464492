import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import Consent from '../components/consent/Consent';
import { Footer, Header, Sidebar } from './../components/index';
import './page.css';

type Props = {
  language: string;
  onSetLanguage: (choice: string) => void;
}

const MainPage: React.FC<Props> = ({ language, onSetLanguage }) => {
  // Code regarding showing the sidebar or not
  const [showSidebar, setShowSidebar] = useState(false);
  const handleToggleSidebar = () => { setShowSidebar((prev) => !prev); } 

    // Function to handle clicks anywhere
    const handleClick = () => {
      // If the sidebar is open, fold it back if clicking on a link or outside - that is: anywhere
      setShowSidebar(false);
      
    };
  
    useEffect(() => {
      // Add the event listener for mousedown
      document.addEventListener('mousedown', handleClick);
  
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, []); // Empty dependency array because we don't need to re-run this effect
  

  return (
    <>
    <Header language={language} onSetLanguage={onSetLanguage} onToggleSidebar={handleToggleSidebar} showSidebar={showSidebar} />
    <Sidebar language={language} active={showSidebar} />
    <div className='page__contents'>
      <Outlet />
    </div>
    <Footer language={language} />
    <Consent language={language} />
    </>
  )
}

export default MainPage;