import React from 'react'
import { NavLink } from "react-router-dom";
import Navigation from '../navigation/Navigation';
import LanguageSelection from './LanguageSelection';
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Logo from '../../assets/NyLogga-transparent.png';
import './header.css';
import '../buttons/buttons.css';

type Props = {
  language: string;
  onSetLanguage: (choice: string) => void;
  onToggleSidebar: () => void;
  showSidebar: boolean
}

const Header = (props: Props) => {
  return (
    <div className='header__contents'>
      <NavLink className='header__content-logo' to="/">
        <img src={Logo} alt='logo' />
      </NavLink>
      <div className='header__navigation'>
        <Navigation language={props.language} />
      </div>
      <div className='header__buttons'>
        <LanguageSelection language={props.language} onSetLanguage={props.onSetLanguage} />
        <div className='header__sidebar-button button__all' onClick={ props.onToggleSidebar }>
          {(props.showSidebar ? <AiOutlineClose size={24} />  : <AiOutlineMenu size={24} />)}
        </div>
      </div>
    </div>
  )
}

export default Header;