import React, { useState } from 'react';
import { resetCookies } from '../../functionality/cookies';
import './privacy.css';
import '../../components/buttons/buttons.css';

// All text on this page
let text = require('./text.json');

type Props = {
  language: string;
}

const Cookies = (props: Props) => {
  return (
    <>
    <div className='page__spacing' />

    <div className='privacy blocks__container'>
      <h1 className='h2 text-center'> {text[props.language]["cookie"]} </h1>
      <p className='p2 half-width'>
        <span> {text[props.language]["cookies-1"]} </span>
        <span> {text[props.language]["cookies-2"]} </span>
      </p>
      <p className='p2 half-width'>
        <span> {text[props.language]["cookies-3"]} </span>
      </p>
      <ol className='p2 half-width'>
        <li>{text[props.language]["cookies-3a"]}</li>
        <li>{text[props.language]["cookies-3b"]}</li>
        <li>{text[props.language]["cookies-3c"]}</li>
      </ol>
      <p className='p2 half-width'>
        <span> {text[props.language]["cookies-4"]} </span>
        <span> {text[props.language]["cookies-5"]} </span>
        <span> {text[props.language]["cookies-6"]} </span>
      </p>
      <button onClick={resetCookies} className='button__box privacy__cookie-button b1'> {text[props.language]["cookies-button"]} </button>
    </div>

    <div className='page__spacing' />
    </>
  )
}

export default Cookies;