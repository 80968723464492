import React from 'react'
import { ButtonOutline, ButtonIcon, ButtonBox, BlockContact, BlockTextAndImage, BlockLanding, BlockHighlights } from '../../components';
import { Carousel } from "antd";
// Assets
import { AiOutlineRight } from "react-icons/ai";
import LandingImage from '../../assets/products_landing.jpg';

import ProdArmaImage from '../../assets/prod_arma.jpg';
import ProdKabelImage from '../../assets/prod_kabel.jpg';
import ProdFundImage from '../../assets/prod_fund.jpg';
import ProdLjusImage from '../../assets/prod_ljus.jpg';
import ProdSkapImage from '../../assets/prod_skap.jpg';

import TjansterImage from '../../assets/tjanster.jpg';
import SpecialImage from '../../assets/special.jpg';
import ContactImage from '../../assets/contact_office.jpg';

// Styles
import '../page.css';
import './products.css';

// All text on this page
let text = require('./text.json');

// Date-types for this component
type Props = {
  language: string;
}

// The Products page component
const Products = (props: Props) => {
  return (
    <>
    <BlockLanding
      title={text[props.language]["landing-title"]}
      image={LandingImage}
      content={<>
        <Carousel autoplay draggable className='products__landing-products'>
          <div className='products__landing-products_product'>
            <img src={ProdFundImage} alt='Product 1' />
            <p className='p-bold2 text-white'> {text[props.language]["offers-1-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdKabelImage} alt='Product 2' />
            <p className='p-bold2 text-white'> {text[props.language]["offers-2-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdArmaImage} alt='Product 3' />
            <p className='p-bold2 text-white'> {text[props.language]["offers-3-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdLjusImage} alt='Product 4' />
            <p className='p-bold2 text-white'> {text[props.language]["offers-4-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdSkapImage} alt='Product 5' />
            <p className='p-bold2 text-white'> {text[props.language]["offers-5-title"]} </p>
          </div>
      </Carousel>
      </>}
    />

    <div className='page__spacing' />

    <BlockHighlights
      defaultKey='products_highlight'
      slogan={text[props.language]["offers-slogan"]}
      title={text[props.language]["offers-title"]}
      text={text[props.language]["offers-text"]}
      
      highlight_images={[ProdFundImage, ProdKabelImage, ProdArmaImage, ProdLjusImage, ProdSkapImage]}
      highlight_titles={[
        text[props.language]["offers-1-title"],
        text[props.language]["offers-2-title"],
        text[props.language]["offers-3-title"],
        text[props.language]["offers-4-title"],
        text[props.language]["offers-5-title"]
      ]}
      highlight_texts={[
        text[props.language]["offers-1-text"],
        text[props.language]["offers-2-text"],
        text[props.language]["offers-3-text"],
        text[props.language]["offers-4-text"],
        text[props.language]["offers-5-text"]
      ]}
      filled={false}
      buttons={<>
        <ButtonOutline color='black' text={text[props.language]["offers-button-request"]} url='/contact#form?brochure' />
        <ButtonIcon icon={AiOutlineRight} color='black' text={text[props.language]["offers-button-contact"]} url='/contact' />
      </>}
      />

      <div className='page__spacing-double' />

      <BlockTextAndImage 
        imageSide='right'
        slogan={text[props.language]["services-slogan"]}
        title={text[props.language]["services-title"]}
        text={text[props.language]["services-text"]}
        image={TjansterImage}
        filled={true}
        buttons={<>
          <ButtonOutline color='white' text={text[props.language]["services-button-request"]} url='/contact#form?service' />
          <ButtonIcon icon={AiOutlineRight} color='black' text={text[props.language]["services-button-questions"]} url='/contact#form' />
        </>}
      />

      <div className='page__spacing-double' />

      <BlockTextAndImage 
        imageSide='left'
        slogan={text[props.language]["custom-slogan"]}
        title={text[props.language]["custom-title"]}
        text={text[props.language]["custom-text"]}
        image={SpecialImage}
        filled={false}
        buttons={<>
          <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
            <ButtonOutline color='black' text={text[props.language]["custom-button-request"]} url='/contact#form?customization' />
          </div>
        </>}
      />

      <div className='page__spacing-double' />

      <BlockContact
        title={text[props.language]["contact-title"]}
        text={text[props.language]["contact-text"]}
        image={ContactImage}
        buttons={<>
          <ButtonBox color='white' text={text[props.language]["contact-button-contact"]} url='/contact' />
          <ButtonOutline color='white' text={text[props.language]["contact-button-request"]} url='/contact#form?brochure' />
        </>}
      />
    </>
  )
}

export default Products;