import React from 'react'
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

// All text on this page
let text = require('./text.json');

const employeesPlaceholder = [
  {
    name: "Time Bergström",
    role: "Operativ Chef",
    phone: "070 XXX XXXX",
    mail: "timo.bergstrom@infragross.se",
    image: ""
  },
  {
    name: "Person 2",
    role: "Some Role",
    phone: "070 XXX XXXX",
    mail: "person2@infragross.se",
    image: ""
  },
  {
    name: "Person 3",
    role: "Some Role",
    phone: "070 XXX XXXX",
    mail: "person3@infragross.se",
    image: ""
  }
]


/**
 * @brief Generates all highlights for the Highlight component
 * @param key A unique react key for all highlights
 * @param titles The title of each individual highlight
 * @param texts The text of each individual highlight
 * @param images The image of each individual highlight
 * @returns Multiple components containing all high lights
 */
function generateHighlights(): React.ReactNode {
  const html = [];

  const fetchedData = employeesPlaceholder;

  for (let i: number = 0; i < fetchedData.length; i++) {
    const key = fetchedData[i].name.replace(/\s/g, "");
    html.push( SingleEmployee(fetchedData[i].name, fetchedData[i].role, fetchedData[i].phone, fetchedData[i].mail, fetchedData[i].image, key) );
  }
  return (<>{html}</>);
}

const SingleEmployee = (name: string, role: string, phone: string, mail: string, image: string, key: string): React.ReactNode  => (
  <div key={key + "_contents"} className='contact__employees-contents_employee'>
    <div key={key + "_image_div"} className='contact__employees-contents_employee-image'>
      <img key={key + "_image"} src={image} alt='Employee' />
    </div>
    <h3 key={key + "_name"} className='p-bold3'> { name } </h3>
    <p key={key + "_role"} className='p3'> { role } </p>
    <div key={key + "_phone-line"} className='contact__employees-contents_employee-line'>
      <AiOutlinePhone size={20} />
      <p key={key + "_phone"} className='p3'> { phone } </p>
    </div>
    <div key={key + "_mail-line"} className='contact__employees-contents_employee-line'>
      <AiOutlineMail size={20} />
      <p key={key + "_mail"} className='p3'> { mail } </p>
    </div>
  </div>
)

// Date-types for this component
type Props = {
  language: string;
}

// The Employees page component
const Employees = (props: Props) => {
  return (
    <>
    <div className='contact__employees'>
      <h2 className='h2 half-width'> { text[props.language]["employees-title"] } </h2>
      <p className='p2 half-width'> { text[props.language]["employees-text"] }  </p>
      <div className='contact__employees-contents'>
        { generateHighlights() }
      </div>
    </div>
    </>
  )
}

export default Employees;