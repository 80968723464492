import React from 'react'
import { NavLink } from "react-router-dom";

// All text on the page
let text = require('./text.json')


type Props = {
  language: string;
}

const Navigation = (props: Props) => {
  return (
    <>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/"
      >
        {text[props.language]["home"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/products"
      >
        {text[props.language]["products"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/about"
      >
        {text[props.language]["about"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/contact"
      >
        {text[props.language]["contact"]}
      </NavLink>
    </>
  )
}

export default Navigation;