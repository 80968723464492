import React from 'react'

// All text on this page
let text = require('./text.json');

// Date-types for this component
type Props = {
  language: string;
}

// The Reviews page component
const Reviews = (props: Props) => {
  return (
    <>
    <div className='home__reviews blocks__container'>
      <p className='p-bold1 text-center half-width'>“{text[props.language]["reviews-text"]}”</p>
      <div className='home__reviews-image'></div>
      <h3 className='p-bold2 text-center'>{text[props.language]["reviews-customer-name"]}</h3>
      <p className='p2 text-center'>{text[props.language]["reviews-customer-role"]}</p>
    </div>
    </>
  )
}

export default Reviews;