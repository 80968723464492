import React from 'react'

// All text on this page
let text = require('./text.json');

// Date-types for this component
type Props = {
  language: string;
}

// The Visit page component
const Visit = (props: Props) => {
  return (
    <>
      <h3 className='h2 text-center'> { text[props.language]["visit-us"] } </h3>
      <div className='contact__visit'>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4031.881644406791!2d17.08613017758774!3d59.650634274771456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465e502a275ea913%3A0xf71b92cafe7ad411!2sSk%C3%A4lbygatan%205%2C%20745%2037%20Enk%C3%B6ping!5e0!3m2!1ssv!2sse!4v1724153388100!5m2!1ssv!2sse" 
          width="600" 
          height="450" 
          loading="lazy" 
        ></iframe>

        <div className='contact__visit-info'>
          <p className='p2'> InfraGross AB </p>
          <p className='p-bold3'> Address: </p>
          <p className='p3'> Skälbygatan 5 </p>
          <p className='p3'> 745 37 Enköping </p>
        </div>
          
      </div>
    </>
  )
}

export default Visit;