import React from 'react'
import { ButtonBox, ButtonOutline, BlockContact, BlockTextAndImage, BlockLanding, BlockHighlights } from '../../components';
import FAQ from './FAQ';
// Assets
import LandingImage from '../../assets/about_landing.jpg';
import AboutEffective from '../../assets/about_effective.jpg';
import AboutVision from '../../assets/about_vision.jpeg';
import AboutClimate from '../../assets/about_climate.jpeg';
import AboutDurable from '../../assets/about_durable.jpg';
import StorageImage from '../../assets/storage2.jpg';
import ContactImage from '../../assets/contact_office.jpg';
// Styles
import '../page.css';
import './about.css';

// All text on this page
let text = require('./text.json');

// Date-types for this component
type Props = {
  language: string;
}

// The About page component
const About = (props: Props) => {
  return (
    <>
      <BlockLanding
        title={text[props.language]["landing-title"]}
        image={LandingImage}
        content={<>
          <p className='p3 text-center text-white'> {text[props.language]["landing-text"]} </p>
          <br />
          <div className='page__button-container-horizontal'>
            <ButtonBox color='white' text={text[props.language]["landing-button-why-us"]} url='#highlight' />
            <ButtonOutline color='white' text={text[props.language]["landing-button-faq"]} url='#vision' />
          </div>
        </>}
      />

    <div className='page__spacing' />

    <BlockTextAndImage 
      imageSide='right'
      slogan={text[props.language]["about-slogan"]}
      title={text[props.language]["about-title"]}
      text={text[props.language]["about-text"]}
      image={AboutEffective}
      filled={false}
      buttons={<>
        <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
          <ButtonBox color='black' text={text[props.language]["about-button-contact"]} url='/contact' />
        </div>
      </>}
    />

    <div className='page__spacing-double' id='highlight' />

    <BlockHighlights
      defaultKey='home_highlight'
      slogan={text[props.language]["why-slogan"]}
      title={text[props.language]["why-title"]}
      text={text[props.language]["why-text"]}
      
      highlight_images={[AboutClimate, AboutDurable, StorageImage]}
      highlight_titles={[
        text[props.language]["why-1-title"],
        text[props.language]["why-2-title"],
        text[props.language]["why-3-title"]
      ]}
      highlight_texts={[
        text[props.language]["why-1-text"],
        text[props.language]["why-2-text"],
        text[props.language]["why-3-text"]
      ]}
      filled={true}
      buttons={<></>}
    />

<div className='page__spacing-double' id='highlight' />

    <BlockTextAndImage 
      imageSide='left'
      slogan={text[props.language]["vision-slogan"]}
      title={text[props.language]["vision-title"]}
      text={text[props.language]["vision-text"]}
      image={AboutVision}
      filled={false}
      buttons={<>
      <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
        <ButtonBox color='black' text={text[props.language]["vision-button-contact"]} url='/contact' />
      </div>

      </>}
    />
{/* 
    <div className='page__spacing-double' id='faq' />

    <FAQ language={props.language} /> */}

    <div className='page__spacing-double' id='vision' />

      <BlockContact
        title={text[props.language]["contact-title"]}
        text={text[props.language]["contact-text"]}
        image={ContactImage}
        buttons={<>
          <ButtonBox color='white' text={text[props.language]["contact-button-contact"]} url='/contact' />
          <ButtonOutline color='white' text={text[props.language]["contact-button-request"]} url='/contact#form?brochure' />
        </>}
      />

    </>
  )
}

export default About;